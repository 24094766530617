import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue')
    // component: () => import('../views/HomePageBroadcasts.vue')
  },
  {
    path: '/schedule/all',
    name: 'SchedulePageAll',
    // props: true,
    props: {
      pageName: 'all',
    },
    component: () => import('../views/SchedulePage.vue'),
  },
  {
    path: '/schedule/weekday/:weekdayNumber',
    name: 'SchedulePageWeekday',
    props: true,
    component: () => import('../views/SchedulePage.vue'),
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    component: () => import('../views/SchedulePage.vue'),
  },
  {
    path: '/shows',
    name: 'ShowsPage',
    component: () => import('../views/SchedulePage.vue'),
  },
  // {
  //   path: '/calendar',
  //   name: 'CalendarPage',
  //   props: true,
  //   component: () => import('../views/CalendarPage.vue')
  // },
  {
    path: '/shows/:showName',
    name: 'ShowPage',
    props: true,
    component: () => import('../views/ShowPage.vue')
  },
  {
    path: '/broadcasts/:broadcastId',
    name: 'BroadcastPage',
    props: true,
    component: () => import('../views/BroadcastPage.vue')
  },
  {
    path: '/profiles/:profileId',
    name: 'ProfilePage',
    props: true,
    component: () => import('../views/ProfilePage.vue')
  },
  {
    path: '/chat',
    name: 'ChatPage',
    component: () => import('../views/ChatPage.vue')
  },
  {
    path: '/more',
    name: 'MorePage',
    component: () => import('../views/MorePage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
