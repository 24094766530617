<template>
  <div class="creek-broadcast-panel">

    <div 
      class="creek-broadcast-panel-error" 
      v-if="globalPlayerError" 
      @click="this.$store.commit('creek/globalPlayer/clearError')"
    >
      Could not load media. Please try again.
    </div>

      <!-- v-text="globalPlayerError" -->

    <div class="creek-broadcast-panel-control" v-if="hasControls">
      <CreekPlayerControl 
        :broadcast="globalPlayerBroadcast"
        :stream="globalPlayerStream"
        :onlyIfPlayable="true"
      />
    </div>

    <div class="creek-broadcast-panel-info">

      <!-- Show -->
      <div v-if="current && current.show" class="show">
        <a 
          class="creek-current-show-title" 
          v-text="current.show.title"
          @click="navigateTo('shows/' + current.show.name, router)"
        ></a>
        <p 
          v-if="includeShowSummary && current.show.summary" 
          v-text="current.show.summary"
        ></p>
        <!-- <p v-if="current.show.description" v-text="current.show.description"></p> -->
      </div>
      <div v-if="!current || !current.show" class="show">
        <span 
          class="creek-studio-title" 
          v-text="studio ? studio.title : null"
        ></span>
      </div>

      <!-- Time -->
      <div 
        class="creek-broadcast-panel-time" 
        v-if="includeTime && current && current.segment"
      >
        <span v-text="formatTime(current.segment.start)"></span> -
        <span v-text="formatTime(current.segment.end)"></span>
      </div>

      <!-- Users -->
      <div 
        class="creek-broadcast-panel-hosts" 
        v-if="includeHosts && current && current.show && current.show.users"
      >
        <!-- TODO: Link to Profile -->
        <!--
        <router-link 
          :to="`/profiles/${user.id}`"
          class="user" 
          v-for="user in current.show.users" 
          v-text="user.display_name || user.name" 
        />
        -->
        <b 
          class="creek-host" 
          v-for="user in current.show.users" 
          v-text="user.display_name || user.name" 
        ></b>
      </div>

      <!-- Broadcast -->
      <!--
      <div class="creek-broadcast">
        <h3 
          v-if="current && current.broadcast"
          v-text="current.broadcast.title"
        ></h3>
      </div>
      -->

      <div v-if="studio && studio.streamPrimary" class="creek-direct-stream-link">
        <a 
          :href="studio.streamPrimary.url" 
          target="blank"
          class="audio-only-button"
        >
          Direct Stream Link
        </a>
      </div>

    </div>

  </div>
</template>

<script>

import fecha from 'fecha'

import CreekPlayerControl from '@creek-website-toolkit/components/controls/PlayerControl.vue'
import navigate from '@creek-website-toolkit/lib/navigate.js'

export default {
  name: 'CreekBroadcastPanel',
  props: {
    hasControls: {
      type: Boolean,
      default: true
    },
    includeShowSummary: {
      type: Boolean,
      default: true
    },
    includeHosts: {
      type: Boolean,
      default: true
    },
    includeTime: {
      type: Boolean,
      default: true
    },
  },
  data(){
    return {
      refreshInterval: null,
    }
  },
  components: {
    CreekPlayerControl
  },
  computed: {
    current() {
      return this.$store.state.creek.studio.current
    },
    studio() {
      return this.$store.state.creek.studio.studio
    },
    globalPlayerBroadcast() {
      return this.$store.state.creek.globalPlayer.sources.broadcast
    },
    globalPlayerStream() {
      return this.$store.state.creek.globalPlayer.sources.stream
    },
    globalPlayerError() {
      return this.$store.state.creek.globalPlayer.error
    }
  },
  methods: {
    async refresh(){ 
      await this.$store.dispatch('creek/studio/getCurrent')
      // X('refreshing')
    },
    formatTime(time){
      var parsedTime = fecha.parse(time, 'isoDateTime');
      return fecha.format(parsedTime, 'h:mma');
    },
    navigateTo(path){
      navigate.to(path, this.$router)
    }
  },
  created(){
    // X('wooof')
    this.refresh()
    // this.refreshInterval = setInterval(this.refresh, 10000)
  },
  beforeDestroy(){
    clearInterval(this.refreshInterval)
  }
}
</script>
