<template>
  <div 
    id="app-container"
    :class="{
      'creek-chat-open': isChatOpen
    }"
  >


    <main>

      <CreekVideoPanel />

      <div class="page-container">

        <router-view/>

      </div>

      <!--
      <div class="all-shows">
        <router-link to="/schedule">Browse All Shows</router-link> &rarr;
      </div>
      -->

    </main>

    <CreekChatToolbar
      :isAlwaysOpen="true"
    /> 
    

    <NavigationPanel />

    <!-- TODO: <teleport> broadcast-toolbar from CreekBroadcastToolbar.vue to the <body> tag. -->

    <!-- TODO: Make sure spacer height is always toolbar height. -->
    <!-- <CreekBroadcastToolbarSpacer/> -->

  </div>
</template>

<style lang="sass">

@import "./sass/basic/main.sass"
@import "./sass/basic/chat.sass"
@import "./sass/basic/dark-1.sass"
// @import "./sass/basic-dark-1.sass"

</style>

<script>

import CreekVideoPanel from '@creek-website-toolkit/components/panels/VideoPanel.vue'
import CreekChatToolbar from '@creek-website-toolkit/components/toolbars/ChatToolbar.vue'
import CreekBroadcastPanel from '@creek-website-toolkit/components/panels/BroadcastPanel.vue'
import NavigationPanel from './components/NavigationPanel.vue'

export default {
  name: 'App',
  components: {
    CreekVideoPanel,
    CreekChatToolbar,
    CreekBroadcastPanel,
    NavigationPanel,
  },
  computed: {
    isChatOpen(){
      return true
      return this.$store.state.creek.studio.UI.isChatToolbarOpen
    }
  }
}

</script>
