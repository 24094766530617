
// NPM
import { createApp } from 'vue'
import { createStore } from 'vuex'

// Local
import router from './router'

// Main Component
import VideoApp from './VideoApp.vue'
// import App from './App.vue'

// Store
import CreekStudioModule from '@creek-website-toolkit/store/CreekStudioModule'

/*
-----------------------------------------------------------
  Shortcuts
-----------------------------------------------------------
*/

window.X = console.log

/*
-----------------------------------------------------------
  Vuex Store
-----------------------------------------------------------
*/

const store = createStore({
  modules: {
    creek: CreekStudioModule
  }
})

// store.dispatch('creek/studio/init', 'atxsoulradio.studio.creek.org')
store.dispatch('creek/studio/init', 'werw.studio.creek.org')

/*
-----------------------------------------------------------
  Vue
-----------------------------------------------------------
*/

// const app = createApp(App)
const app = createApp(VideoApp)

app.use(store)
app.use(router)

app.mount('#app')

