<template>

<div 
  class="creek-chat-toolbar" 
  :class="{ 
    'creek-chat-open': isOpen, 
    'creek-chat-closed': !isOpen
  }"
>

  <div 
    class="creek-chat-toolbar-container-open"
    v-if="isOpen"
  >
    
    <button 
      class="creek-chat-exit" 
      @click="closeChat()"
      v-if="!isAlwaysOpen"
    >
      <i class="fa fa-close fa-lg"></i> Exit Chat
    </button>

    <CreekChatPanel />
    <!--
    <button
      @click="$store.commit('openChatPanel')"
    > 
    -->

  </div>

  <div 
    class="creek-chat-toolbar-container-closed" 
    v-if="!isOpen"
  >
    <button
      @click="openChat()" 
    >
    
      <!--<span v-text="userCount"></span> <span v-text="userCount > 1 || userCount == 0 ? 'people' : 'person'"></span> in chat.-->
      
      <!-- <i class="fa fa-comment-o fa-lg"></i> -->

      <span v-text="userCount ? userCount : '1'"></span>
      &nbsp;user<span v-text="userCount > 1 ? 's' : ''"></span> in chat.
    
    </button>
  </div>

</div>

</template>

<script>

import CreekChatPanel from '@creek-website-toolkit/components/panels/ChatPanel.vue'

export default {
  name: 'CreekChatToolbar',
  props: {
    isAlwaysOpen: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isOpen(){
      return this.isAlwaysOpen ? true : this.$store.creek.studio.UI.isChatToolbarOpen
    }
  },
  components: {
    CreekChatPanel
  },
  data(){
    return {

    }
  },
  created(){
    if(this.isAlwaysOpen){
      this.$store.commit('creek/studio/openChatToolbar')
      // alert(1)
    }
  }
}
</script>
