<template>
  <nav class="navigation-panel">
    <router-link to="/">
      <div class="button-icon"><img src="@/assets/icons/home-lg.svg" /></div>
      <div class="button-text">Home</div>
    </router-link>
    <!--
    <router-link to="/shows">
      <div class="button-icon"><img src="@/assets/icons/podcast.svg" /></div>
      <div class="button-text">Shows</div>
    </router-link>
    -->
    <router-link 
      class="chat-button"
      to="/chat"
    >
      <div class="button-icon"><img src="@/assets/icons/comment-alt.svg" /></div>
      <div class="button-text">Chat</div>
    </router-link>
    <!--<button 
      class="chat-button"
      :class="{
        'button-active': $store.state.creek.studio.UI.isChatToolbarOpen
      }"
      @click="showChat()"
    >
      <div class="button-icon"><img src="@/assets/icons/comment-alt.svg" /></div>
      <div class="button-text">Chat</div>
    </button>
  -->
  <!--
    <router-link to="/more">
      <div class="button-icon"><img src="@/assets/icons/bars.svg" /></div>
      <div class="button-text">More</div>
    </router-link>
  -->
  </nav>
</template>

<script>
export default {
  name: 'NavigationPanel',
  methods: {
    showChat(){
      this.$store.commit('creek/studio/activateChatTab')
    }
  }
}
</script>
