<template>
  <div class="creek-video-panel">

    <!--
    <div class="creek-video-panel-button-container">
      <button 
        @click="unmute()" 
        class="creek-video-panel-button"
        v-if="muted == true"
      >
        Start
      </button>
    </div>
    -->

    
    <div
      :class="{ 'is-hidden': currentSourceName != 'dj-1' }"
    >
      <video
        ref="videoPlayer_dj-1"
        id="videoPlayer_dj-1" 
        class="video-js vjs-default-skin"
      >
        <source
           src="https://werw-video.creek.org/segments/hls/dj-1.m3u8"
           type="application/x-mpegURL" 
        />
      </video>
    </div>
    <div
      :class="{ 'is-hidden': currentSourceName != 'dj-2' }"
    >
      <video
        ref="videoPlayer_dj-2"
        id="videoPlayer_dj-2" 
        class="video-js vjs-default-skin"
      >
        <source
           src="https://werw-video.creek.org/segments/hls/dj-2.m3u8"
           type="application/x-mpegURL" 
        />
      </video>
    </div>
    <div
      :class="{ 'is-hidden': currentSourceName != 'studio-1' }"
    >
      <video
        ref="videoPlayer_studio-1"
        id="videoPlayer_studio-1" 
        class="video-js vjs-default-skin"
      >
        <source
           src="https://werw-video.creek.org/segments/hls/studio-1.m3u8"
           type="application/x-mpegURL" 
        />
      </video>
    </div>
    

    <!--
    <div
      v-for="sourceName in sourceNames"
      :class="{ 'is-hidden': currentSourceName != sourceName }"
    >
      <video
        :ref="'videoPlayer_' + sourceName"
        :id="'videoPlayer_' + sourceName"
        class="video-js vjs-default-skin"
      >
        <source
           :src="`https://werw-video.creek.org/segments/hls/${sourceName}.m3u8`"
           type="application/x-mpegURL" 
        />
      </video>
    </div>
  -->

<!--       <source
         :src="initialURL"
         type="application/x-mpegURL" 
      />  -->
    <!-- 
    -->
      <!-- 
      <source
         src="https://werw-video.creek.org/segments/hls/studio-1_480.m3u8"
         type="application/x-mpegURL" 
      />
      <source
         src="https://werw-video.creek.org/segments/hls/dj-2_480.m3u8"
         type="application/x-mpegURL" 
      /> 
    -->

    <!-- </video> -->

  </div>
</template>

<script>

import videojs from 'video.js'

// import { videoPlayer } from 'vue-video-player'

import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/city/index.css'

import axios from 'axios'


export default {
  name: 'CreekVideoPanel',
  // components: {
  //   videoPlayer
  // },
  data() {
    return {
      players: {},
      sourceNames: ['dj-1', 'dj-2', 'studio-1'],
      muted: true,
      intervals: {
        checkRemoteDJ: null,
        checkMuted: null,
      },
      currentSourceName: null,
      defaultSourceName: 'studio-1',
      // currentURL: `https://werw-video.creek.org/segments/hls/studio-1.m3u8`,
      initialURL: `https://werw-video.creek.org/segments/hls/studio-1_480.m3u8`,
      currentURL: null,
      // defaultSourceName: 'dj-2',
    }
  },
  // computed: {
  //   videoURL(){

  //     // if(!this.currentURL){
  //     //   return `https://werw-video.creek.org/segments/hls/studio-1.m3u8`
  //     // }

  //     return this.currentURL

  //   //   // return `https://werw-video.creek.org/segments/hls/dj-test-1.m3u8`
      
  //   //   const sourceName = this.currentSourceName ? this.currentSourceName : this.defaultSourceName

  //   //   // return `https://werw-video.creek.org/segments/hls/${sourceName}.m3u8`

  //   //   return `https://werw-video.creek.org/segments/hls/studio-1.m3u8`
  //   //   // return `https://werw-video.creek.org/segments/hls/dj-2.m3u8`
  //   }
  // },
  methods: {
    unmute(){
      this.player.play()
      if(this.player){
        this.player.muted(false)
      }
      this.muted = false
      X('wooooo')
    },
    checkMuted(){
      if(!this.player){
        X('player unlinked?')
        return false
      }
      if(this.player && this.player.muted){
        this.muted = this.player.muted() ? true : false
        // X(this.player.muted())
      }
    },
    getVideoURL(sourceName){
      // return `https://werw-video.creek.org/segments/hls/dj-1.m3u8`
      return `https://werw-video.creek.org/segments/hls/${sourceName}_480.m3u8`
    },
    async checkRemoteDJ(){

      let response = null

      // TODO: Use this domain structure, with a wildcard for studio.name
      // const URL = `https://${studio.name}.remote-dj.creek.org/bobcat-server/api/status`

      const URL = `https://werw-remote-dj.creek.org/bobcat-server/api/status`
      // const URL = `https://${window.location.hostname}/bobcat-server/api/status`

      // X('oaishdoiashdoashdiojhkfaeguh')
      // X('checking')

      response = await axios.get(URL)

      if(
        !response.data 
        || !response.data.instances
        || !response.data.instances['instance-1'].sources
      ){
        return null
      }

      const sources = response.data.instances['instance-1'].sources

      // Default to studio 1.
      let currentSourceNameFromAPI = null

      for(let sourceName in sources){

        // X('checking: ' + sourceName)

        const source = sources[sourceName]

        // X(source.volume)
        // X(parseInt(source.volume) > 0)
        // X(source.volume > 0)
        // X(source.volume == 0)
        // X(source.volume == 1)

        if(currentSourceNameFromAPI){
          continue
        }

        if(parseInt(source.volume) > 0 && source.isActive){
          currentSourceNameFromAPI = sourceName
          // X('yeahsdasdasdasd')
          X('found: ' + sourceName)
        }

      }

      let shouldUpdate = false

      // If API result is different, update source.
      if(
        currentSourceNameFromAPI 
        && this.currentSourceName != currentSourceNameFromAPI
      ){
        X('updating to: ' + currentSourceNameFromAPI)
        this.currentSourceName = currentSourceNameFromAPI
        shouldUpdate = true
      }
      
      // If API result is empty, update source to default.
      if(
        !currentSourceNameFromAPI 
        && this.currentSourceName != this.defaultSourceName
      ){
        X('updating to default')
        this.currentSourceName = this.defaultSourceName
        shouldUpdate = true
      }

      // If update needed, update video source.
      if(shouldUpdate){

        X('updating...')
        
        // this.currentURL = this.getVideoURL(this.currentSourceName)      
        
        

        for(let sourceName of this.sourceNames){

          if(!this.players[sourceName]){
            continue
          }

          X('updating vjs: ' + sourceName)
          
          if(this.currentSourceName != sourceName){
            // this.players[sourceName].reset()
            this.players[sourceName].pause()
          }
          if(this.currentSourceName == sourceName){
            X('switching source to: ' + sourceName)
            // this.players[sourceName].load()
            this.players[sourceName].play()
          }

        }


        // this.buildPlayer(this.currentSourceName)

        // this.player.src('https://werw-video.creek.org/segments/hls/dj-2.m3u8')
        // this.player.src(this.currentURL)

        // this.player.src([{
        //   src: this.currentURL,
        //   type: 'application/x-mpegURL',
        // }])
        // this.player.load()
        // this.player.play()

      }

    },
    buildPlayer(sourceName){

      // if (this.player && this.player.dispose) {
      //   // return
      //   this.player.dispose()
      //   this.player = null
      // }

      // this.$forceUpdate()

      const options = {
        controls: true, 
        // autoplay: true, 
        autoplay: 'any', 
        // preload: "none",
        preload: "auto",
        fill: true,
        fluid: true,
        sources: [
          {
            src: this.getVideoURL(sourceName),
            type: 'application/x-mpegURL',
          }
        ]
      }


      // this.options.sources = [{
      //   src: sourceURL,
      //   type: 'application/x-mpegURL',
      // }]

      // setTimeout(() => {
        this.players[sourceName] = videojs(
          this.$refs['videoPlayer_'+sourceName], 
          options,
          function onPlayerReady() {
            X('onPlayerReady: ' + sourceName)
          }
        )
      // }, 1000)

    }
  },

  // Use mounted (not created) so that videoPlayer ref exists.
  async mounted() {


    this.currentURL = this.initialURL
    
    // await this.checkRemoteDJ()

    setTimeout(() => {

      this.buildPlayer('dj-1')
      this.buildPlayer('dj-2')
      this.buildPlayer('studio-1')
      // this.buildPlayer(this.currentSourceName)

    }, 1000)

    setTimeout(() => {
    
    this.checkRemoteDJ()

    }, 1000)

    X('wool')

    this.intervals.checkRemoteDJ = setInterval(this.checkRemoteDJ, 5000)
    // this.intervals.checkMuted = setInterval(this.checkMuted, 2000)

  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
    clearInterval(this.intervals.checkRemoteDJ)
    // clearInterval(this.intervals.checkMuted)
  }
}
</script>
<style lang="sass">

.is-hidden
  display: none

</style>